/* ========================================================================== 
0. MIXIN / MEDIA / ALL VARIABLES
========================================================================== */

/* Typography imported from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;800&display=swap');

/* Main */

html {
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  font-family: 'Spectral', serif;
  scroll-behavior: smooth;
  color: midnightblue;
}

body {
  font-size: 16px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

.home {
  overflow: hidden;
}

/* Fonts */

h1 {
  font-family: 'EB Garamond', serif;
  font-size: 2rem;
  font-weight: 400;
  word-spacing: 5px;
  -webkit-font-smoothing: auto;
}

h2 {
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 60px;
  letter-spacing: -1.45px;
}

h3 {
  font-family: 'EB Garamond', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 10px;
  letter-spacing: -1.45px;
}



/* Links */

a {
  text-decoration: none;
}

a:link {
  color: #0b2469;

}

a:visited {
  color: #0b2469;
  opacity: 0.9;
}

a:hover {
  color: #0e3cb9;
  opacity: 0.8;
  text-decoration: underline;
}



/* Breakpoints */

/* $breakpoints: (
    xs:     < 576px;        x small
    sm:     <= 576px;       small
    md:     >= 768px;       medium          done
    l:      >= 992px;       large
    xl:     >= 1200px;      x large
    xxl:    >= 1400px;      xx large
); */


/* ========================================================================== 
1. Breakpoint for Header, Navigation, Footer and About
========================================================================== */

/* HEADER */


.header {
  height: 200px;
}


.header__title {
  font-size: 3.5rem;
  text-align: center;
}

.header_title_class {
  text-decoration: none;
}

.header_title_class:hover {
  text-decoration: underline;
}

/* NAVIGATION */

.navigation {
  display: grid;
  height: 200px;
  grid-template-columns: 5% 20% 2.5% 20% 5% 20% 2.5% 20% 5%;
  grid-template-rows: 5% 20% 20% 15% 10%;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . . . . ."
    ". code__img . design__img . developer__img . artist__img ."
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". code__title . design__title . developer__title . artist__title .";
}

.code__img {
  grid-area: code__img;
  max-width: 100%;
  justify-self: center;
}

.code__title {
  grid-area: code__title;
  text-align: center;
}

.developer__img {
  grid-area: developer__img;
  max-width: 100%;
  justify-self: center;
}

.design__img>img,
.artist__img>img {
  border-radius: 50px;
}

.design__img {
  grid-area: design__img;
  max-width: 100%;
  justify-self: center;
}


.developer__title {
  grid-area: developer__title;
  text-align: center;

}

.design__title {
  grid-area: design__title;
  text-align: center;
}

.artist__title {
  grid-area: artist__title;
  text-align: center;
}

.artist__img {
  grid-area: artist__img;
  max-width: 100%;
  justify-self: center;
}

.dev__container {
  display: flex;
  flex-direction: column;
}

.dev__text-image {
  justify-self: center;
  align-self: center;
  max-width: 80vw;
}

.dev__text-image img {
  max-width: 100%;
}

.dev__text {
  text-align: center;
  font-size: 1.5rem;
  line-height: 30px;
  font-weight: 300;
  margin: 5px 5px;
  padding-left: 20px;
  padding-right: 20px;
}

/* FOOTER */

.footer {
  margin-top: 20px;
  height: 70px;
  position: relative;
  border-top: 1px solid #E6E8E9;
}

.footercontainer {
  text-align: center;
  font-size: 0.7rem;
}


.socialmedia {
  text-align: center;
}

.socialmedia img {
  width: 24px;
  height: 24px;
}

.socialmedia a {
  padding-left: 8px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.socialmedia li:hover {
  opacity: .5;
}

/* ABOUT */

.about__title-container {
  display: grid;
  height: 100px;
  grid-template-columns: 5% 10% 15% 20% 20% 15% 10% 5%;
  grid-template-rows: 30% 70%;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . . ."
    ". . . about__title about__title . . .";

}

.about__title {
  grid-area: about__title;
  display: flex;
  text-align: center;
  justify-content: center;
  position: relative;
  font-size: 3rem;
  letter-spacing: -2px;
}

.About {
  display: grid;
  position: relative;
  height: 250px;
  grid-template-columns: 5% 44% 2% 44% 5%;
  grid-template-rows: 5% 15% 55% 15% 10%;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . ."
    ". about__img . about__text ."
    ". about__img . about__text ."
    ". about__img . about__text ."
    ". . . . .";
}

.about__img {
  grid-area: about__img;
  max-width: 100%;
}

.about__img img {
  justify-self: center;
}

.about__text {
  position: absolute;
  /* text-align: left; */
  padding: 20px;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
  box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
  grid-area: about__text;
  max-width: 100%;
}

.about__text p {
  font-size: 0.6rem;
  line-height: 12px;
  font-weight: 400;
}

.about__text-titel {
  font-size: 0.8rem;
  line-height: 10px;
  font-weight: 500;
  margin-bottom: 20px;
}

@media (min-width: 1025px) {
  .dev__text-image {
    justify-self: center;
    align-self: center;
    max-width: 80vw;
    height: 500px;
  }
  
  .dev__text {
    text-align: center;
    font-size: 1.3rem;
    line-height: 30px;
    font-weight: 300;
    margin: 5px 5px;
  }

  .navigation {
    display: grid;
    height: 250px;
    grid-template-columns: 15% 10% 10% 10% 10% 10% 10% 10% 15%;
    grid-template-rows: 5% 20% 20% 15% 10%;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . . . . . . . ."
      ". code__img . design__img . developer__img . artist__img ."
      ". . . . . . . . ."
      ". . . . . . . . ."
      ". code__title . design__title . developer__title . artist__title .";
  }

  .navigation img {
    transform: scale(1.5);
  }

  .header_title_class p {
    font-size: 4rem;
  }

  .navigation {
    font-size: 1.5rem;
  }

  .footer {
    margin-top: 50px;
    height: 100px;
    position: relative;
    /* display: grid;
    grid-template-columns: 10% 50% 40%;
    grid-template-rows: 10% 20%;
    gap: 0px 2px; */
    border-top: 1px solid #E6E8E9;
  }

  .footercontainer {
    margin-top: -30px;
    font-size: 1.5rem;
  }


  .about__title-container {
    display: grid;
    height: 100px;
    grid-template-columns: 5% 10% 15% 20% 20% 15% 10% 5%;
    grid-template-rows: 30% 70%;
    gap: 0px 0px;
    grid-template-areas:
      ". . . . . . . ."
      ". . . about__title about__title . . .";

  }

  .about__title {
    grid-area: about__title;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    font-size: 3rem;
    letter-spacing: -2px;
  }

  .About {
    display: grid;
    height: 600px;
    grid-template-columns: 15% 30% 10% 30% 15%;
    grid-template-rows: 10% 70% 20%;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . . . ."
      ". about__img . about_text ."
      ". about__img . about_text .";

  }

  .about__img {
    grid-area: about__img;
  }

  .about__img img {
    justify-self: center;
    max-width: 400px;
    border-radius: 500px;
    align-content: center;
  }

  .about__text {
    /* text-align: left; */
    padding-top: -20px;
    background-color: #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
    box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
    grid-area: about_text;
  }

  .about__text p {
    font-size: 1rem;
    margin: 5px auto;
    line-height: 30px;
  }

  .about__text-container {
    font-size: 1.6rem;
    line-height: 10px;
    font-weight: 400;
  }

  .about__text-titel {
    padding-top: 10px;
    font-size: 2rem;
    line-height: 10px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .moodswing__text {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  /* FOOTER */
  .footer {
    margin-top: 50px;
    height: 100px;
    position: relative;
    border-top: 1px solid #E6E8E9;
  }

  .footercontainer {
    margin-top: -10px;
    font-size: 1.2rem;
  }

  .socialmedia img {
    width: 24px;
    height: 24px;
  }

  .socialmedia a {
    padding-left: 0;
    padding-right: 20px;
    text-align: center;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  .socialmedia li:hover {
    opacity: .5;
  }

  /* ABOUT */

  .about__title-container {
    display: grid;
    height: 100px;
    grid-template-columns: 5% 10% 15% 20% 20% 15% 10% 5%;
    grid-template-rows: 30% 70%;
    gap: 0px 0px;
    grid-template-areas:
      ". . . . . . . ."
      ". . . about__title about__title . . .";

  }

  .about__title {
    grid-area: about__title;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    font-size: 3rem;
    letter-spacing: -2px;
  }

  .About {
    display: grid;
    height: 400px;
    grid-template-columns: 15% 30% 10% 30% 15%;
    grid-template-rows: 10% 70% 20%;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . . . ."
      ". about__img . about_text ."
      ". . . . .";

  }

  .about__img {
    grid-area: about__img;
  }

  .about__img img {
    justify-self: center;
    max-width: 500px;
    border-radius: 500px;
  }

  .about__text {
    /* text-align: left; */
    padding-top: -20px;
    background-color: #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
    box-shadow: -9px 25px 35px 0px rgba(0, 0, 0, 0.06);
    grid-area: about_text;
  }

  .about__text-container {
    font-size: 0.6rem;
    line-height: 10px;
    font-weight: 400;
  }

  .about__text-titel {
    font-size: 1rem;
    line-height: 10px;
    font-weight: 500;
    margin-bottom: 20px;
  }

}

/* THRIVE */

.slider__thrive:first-child() {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
  background-color: black;
}


.thrive__title-container {
  display: grid;
  height: 250px;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 15% 40% 45%;
}

.thrive__title-color {
  grid-row: 1/2;
  grid-column: 1/11;
  background-color: rgba(58, 58, 60, 255)
}

.thrive__title-img {
  grid-column: 3/9;
  grid-row: 2/4;
  z-index: -4;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
  height: 80px;
}

.thrive__title {
  grid-column: 3/9;
  grid-row-start: 1;
  height: 80px;
  z-index: 2;
  display: flex;
  text-align: center;
  justify-content: center;
  position: relative;
  font-size: 2.3rem;
  font-family: 'Raleway', sans-serif;
}

.thrive__vid {
  display: grid;
  height: 320px;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 10% 25% 15% 25% 25%;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . . . . ."
    ". . thrive-video thrive-video thrive-video thrive-video thrive-video thrive-video . ."
    ". . thrive-video thrive-video thrive-video thrive-video thrive-video thrive-video . ."
    ". . thrive-video thrive-video thrive-video thrive-video thrive-video thrive-video . ."
    ". . thrive-video thrive-video thrive-video thrive-video thrive-video thrive-video . ."
    ". . . . . . . . . .";
}

.thrive-video {
  grid-area: thrive-video;
  max-width: 100%;
  justify-content: center;
  position: relative;
  display: flex;
}

.thrive {
  height: 400px;
  display: grid;
  grid-template-columns: 5% 15% 15% 30% 15% 15% 5%;
  grid-template-rows: 30% 10% 20% 40%;
  gap: 0px 0px;
  grid-template-areas:
    ". . thrive__eye thrive__eye thrive__eye . ."
    ". . thrive__eye thrive__eye thrive__eye . ."
    ". . thrive__eye thrive__eye thrive__eye . ."
    ". thrive__text thrive__text thrive__text thrive__text thrive__text .";
}

.thrive__text {
  font-style: italic;
  grid-area: thrive__text;
  text-align: center;
  font-size: 1.3rem;
  line-height: 30px;
  font-weight: 300;
  margin: 5px 5px;
}

.thrive__eye {
  grid-area: thrive__eye;
  max-width: 100%;
  display: flex;
  justify-content: center;

}

.thrive__text-container {
  grid-area: thrive__text;
}

.thrive__choices {
  height: 400px;
  display: grid;
  grid-template-columns: 5% 15% 15% 30% 15% 15% 5%;
  grid-template-rows: 5% 45% 40% 10%;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . ."
    ". choice__text choice__text choice__text choice__text choice__text ."
    ". choice__text choice__text choice__text choice__text choice__text ."
    ". . . . . . .";
}

.thrive__choices img {
  justify-self: center;
}

.choice__box {
  grid-column: 2/7;
  grid-row: 1/5;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: auto;
}

.choice__text {
  font-style: italic;
  grid-area: choice__text;
  color: white;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  line-height: 26px;
  font-weight: 300;
  margin: auto 15px;
  padding-top: -15px;
}

.thrive__slide-container {
  grid-column: 1/6;
  grid-row: 1/5;
}

.thrive__slide {
  display: grid;
  height: 250px;
  grid-template-columns: 2% 20% 56% 20% 2%;
  grid-template-rows: 7.5% 7.5% 80% 5%;
  gap: 0px 0px;
  position: relative;
}

.thrive__slide-img {
  grid-area: 1 / 2 / 4 / 5;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}


.thrive__danger {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100%;
  position: relative;
  height: 100px;
  right: 50%;
  width: 100vw;
}

/* MOODSWING */

.ms__logo-container {
  display: grid;
  width: 100%;
  height: 500px;
  grid-template-columns: 100%;
  grid-template-rows: 22.5% 75% 2.5%;
  gap: 0px 0px;
  grid-template-areas:
    "."
    "ms__logo"
    ".";
}

.ms__logo {
  grid-area: ms__logo;
  background-color: black;
  display: flex;
  justify-content: center;
  width: 100%;
}

.moodswing {
  display: grid;
  height: 100px;
  grid-template-columns: 5% 10% 15% 20% 20% 15% 10% 5%;
  grid-template-rows: 30% 70%;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . . ."
    ". . . moodswing__title moodswing__title . . .";
}

.moodswing__title {
  grid-area: moodswing__title;
  z-index: 4;
  display: flex;
  text-align: center;
  justify-content: center;
  position: relative;
  font-size: 3rem;
  letter-spacing: -2px;

}

.moodswing__text-container {
  width: 100vw;
}

.moodswing__text {
  font-style: italic;
  text-align: center;
  font-size: 1.3rem;
  line-height: 30px;
  font-weight: 300;
  margin: 20px 20px;
  padding-left: 50px;
  padding-right: 50px;

}

.moodswing__text>img {
  max-width: 60vw;
  text-align: center;

}


/* Portfolio */

.portfolio {
  height: 500px;
  display: grid;
  grid-template-columns: 10% 20% 10% 20% 10% 20% 10%;
  grid-template-rows: 10% 5% 20% 1fr 20% 1fr 20% 1fr 20%;
  gap: 0px 0px;
  grid-template-areas:
    ". . portfolio__title portfolio__title portfolio__title . ."
    ". . . . . . ."
    ". thedepths . untitled . mswhite ."
    ". . . . . . ."
    ". portals . thrivewings . gardenofeden ."
    ". . . . . . ."
    ". hh . train . mask ."
    ". . . . . . ."
    ". thrivedemon . awakening . vanity .";
}

.portfolio__title {
  grid-area: portfolio__title;
  z-index: 4;
  justify-self: center;
  font-size: 2.4rem;
  letter-spacing: -2px;
}

.portfolio img {
  justify-self: center;
}

.untitled {
  grid-area: untitled;
}

.thedepths {
  grid-area: thedepths;
}

.awakening {
  grid-area: awakening;
}

.oil {
  grid-area: oil;
}

.mask {
  grid-area: mask;
}

.portals {
  grid-area: portals;

}

.gardenofeden {
  grid-area: gardenofeden;
}

.train {
  grid-area: train;
}

.lion {
  grid-area: lion;
}

.hh {
  grid-area: hh;
}

.nightmare {
  grid-area: nightmare;
}

.thrivewings {
  grid-area: thrivewings;
}

.vanity {
  grid-area: vanity;
}

.msgreen {
  grid-area: msgreen;
}

.mswhite {
  grid-area: mswhite;
  background-color: black;
}

.thrivedemon {
  grid-area: thrivedemon;
}

.screenshot {
  max-width: 100vw;
}


/* ========================================================================== 
Thrive FAQ
========================================================================== */

.thrive__faq {
  max-width: 700px;
  text-align: center;
}

.faq-question-style {
  width: 100%;
  background-color: rgb(0, 4, 255);
  color: white;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25em;
  margin: 0.25em 0;
  white-space: pre-wrap;
}

.faq-question-style:hover {
  cursor: pointer;
}

.faq-cont {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5em;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.faq-answer-style {
  display: none;
  font-weight: 300;
  width: 100%;
  color: black;
  border-radius: 5px;
  padding: 0 1em;
  text-align: left;
  font-size: 1.25em;
  margin: 0.5em 0;
  white-space: pre-wrap;
}

.faq-show-animation {
  display: block;
  height: fit-content;
  animation: faq-answer-slide 0.8s;
}

.faq-rotate-forwards {
  transform: rotate(180deg);
  transition: 0.2s transform;
}

.faq-rotate-back {
  transform: rotate(0deg);
  transition: 0.2s transform;
}

@keyframes faq-answer-slide {
  0% {
    opacity: 0;
    max-height: 0;
  }

  100% {
    opacity: 1;
    max-height: 500px;
  }
}

/* 
THRIVE SCREEN PAGE
*/

.screenshot__container {
  height: 700px;
  text-align: center;
}

.screenshot__container img {
  max-width: 800px;
}

.screenshot__text {
  font-size: 2rem;
}

.download__text {
  height: 400px;
  font-size: 2rem;
  text-align: center;
  background-color: lightblue;
  border: 5px solid darkblue;
  border-radius: 200px;
  margin-left: 20px;
  margin-right: 20px;
}

.download__text a {
  color: red;
}

.moodswing__parallax {
  width: 1000px;
  height: 705px;
  text-align: center;
  margin: auto;
}

.navigation__parallax {
  display: grid;
  height: 350px;
  background-color: midnightblue;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 25% 50% 25%;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . . . . ."
    ". nav_original nav_original . nav_neon nav_neon . nav_kaleido nav_kaleido ."
    ". . . . . . . . . .";
}

.nav_neon {
  grid-area: nav_neon;
  max-width: 80%;
  justify-self: center;
  align-self: center;
}

.nav_original {
  grid-area: nav_original;
  max-width: 80%;
  justify-self: center;
  align-self: center;
}

.nav_kaleido {
  grid-area: nav_kaleido;
  max-width: 80%;
  justify-self: center;
  align-self: center;
}

.moodswing__info {
  width: 1200px;
  margin: auto;
}

.moodswing__info p {
  text-align: center;
  font-size: 1.5rem;
}